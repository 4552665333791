import axios from "axios";
import React, { useState } from 'react';
import Calendar from 'react-calendar';

function Finance(props) {
    const [stats,setStats] = useState({"purchases":0,"sales":0})
    async function paste() {
        const text = await navigator.clipboard.readText();
        document.getElementById("searcher").value = text;
    }
    const getStartDate = () => {
        let now = new Date();
        now.setHours(0, 0, 0);
        return now;
    }
    const [from, setFrom] = useState(getStartDate())
    const [to, setTo] = useState(new Date());

    const updateCalendar = e => {
        e.setHours(0,0,0);
        setFrom(e);
        setShowCalendar(false)
    }
    const updateSecondCalendar = e =>{
        setTo(e);
        setShowSecondCalendar(false)
    }

    const getFinance = async() =>{
        const url = "https://y70799.xyz/api/v2/finance";
        const data = await axios.post(url,{start_date:(from).getTime(),end_date:(to).getTime()}).then(res=> res.data);
        setStats({"purchases":(data.totalPrice).toFixed(2),"sales":(data.totalSold).toFixed(2)})
    }
    const [showCalendar, setShowCalendar] = useState(false);
    const [showSecondCalendar,setShowSecondCalendar] = useState(false)

    return (
        <div className="c-dialog__scroll">
            <div style={{ backgroundColor: "#1D1F20", padding: "2%" }}>
                <div style={{ display: "flex", marginRight: "1%", overflow: "auto" }}>

                    <div className="c-assetFilters__item o-filter o-filter--search">
                        <button aria-label="Search" className="o-filter__searchButton">
                            <i className="o-filter__icon">
                                <svg>
                                    <symbol id="icon-search" viewBox="0 0 24 24" fill="currentColor">
                                        <path
                                            xmlns="http://www.w3.org/2000/svg"
                                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                                        />
                                    </symbol>
                                    <use xlinkHref="#icon-search" />
                                </svg>
                            </i>
                            {/**/}
                        </button>
                        <label className="o-filter__search ng-untouched ng-pristine ng-valid">
                            <input
                                id="searcher"
                                type="search"
                                aria-label="Search input"
                                formcontrolname="title"
                                data-hj-whitelist=""
                                className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                                placeholder="Search market"
                            />
                            <button aria-label="Clear search" className="o-filter__searchClear" >
                                <i className="o-filter__icon">
                                    <svg>
                                        <symbol id="icon-close" viewBox="0 0 24 24" fill="currentColor">
                                            <path
                                                xmlns="http://www.w3.org/2000/svg"
                                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                                            />
                                        </symbol>
                                        <use xlinkHref="#icon-close" />
                                    </svg>
                                </i>
                                {/**/}
                            </button>
                        </label>
                    </div>


                    <div className="c-assetFilters__item ng-star-inserted">
                        <button
                            aria-label="filter refresh button"
                            matripple=""
                            className="mat-ripple o-filter o-filter--refresh"
                            onClick={() => paste()}
                        >
                            <i className="o-filter__icon">
                                <svg>
                                    <symbol id="icon-refresh" viewBox="0 0 24 24" fill="currentColor">
                                        <path
                                            xmlns="http://www.w3.org/2000/svg"
                                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
                                        />
                                    </symbol>
                                    <use xlinkHref="#icon-refresh" />
                                </svg>
                            </i>
                            {/**/}
                        </button>
                    </div>
                </div>

                <div style={{ display: "flex", marginTop: "2%" }}>
                    <div className="c-assetFilters__item o-filter o-filter--search" style={{ minWidth: "auto", width: "200px" }}>
                        <input
                            id="newItem"
                            style={{ padding: "10px", opacity: ".5" }}
                            type="search"
                            aria-label="Search input"
                            formcontrolname="title"
                            data-hj-whitelist=""
                            className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                            placeholder="New Item"
                        />
                    </div>
                    
                    <button style={{marginRight:"15px"}} onClick={() => setShowCalendar(!showCalendar)}>From</button>   
                    <button style={{marginRight:"15px"}} onClick={() => setShowSecondCalendar(!showSecondCalendar)}>To</button>
 
                    <div className="c-assetFilters__item ng-star-inserted">
                        <button
                            onClick={() => getFinance()}
                            aria-label="filter refresh button"
                            matripple=""
                            className="mat-ripple o-filter o-filter--refresh"
                        >
                            <i className="o-filter__icon">
                                <svg>
                                    <symbol id="icon-target" fill="currentColor" viewBox="0 0 25 25">
                                        <path d="M11.1355 10.7022C11.076 10.698 11.0166 10.6958 10.9571 10.6958C9.65962 10.6958 8.60634 11.7489 8.60629 13.046C8.60629 14.3432 9.65535 15.3963 10.955 15.3963C12.2546 15.3963 13.3057 14.3432 13.3057 13.046C13.3057 12.9866 13.3036 12.9272 13.2993 12.8677L14.9791 11.1884L15.0152 11.1523C15.2849 11.7277 15.4357 12.3688 15.4357 13.0461C15.4357 15.5194 13.431 17.5236 10.9571 17.5236C8.48315 17.5236 6.47852 15.5173 6.47852 13.0439C6.47852 10.5706 8.48315 8.56641 10.9571 8.56641C11.6345 8.56641 12.2758 8.71714 12.8513 8.98677L12.8152 9.02286L11.1355 10.7022Z" />
                                        <path d="M10.9999 4.08605C10.9849 4.08597 10.97 4.08594 10.9551 4.08594C6.01023 4.08594 2 8.09618 2 13.0431C2 17.99 6.01025 22.0003 10.9572 22.0003C15.9041 22.0003 19.9143 17.99 19.9143 13.0431C19.9143 12.2083 19.8005 11.4006 19.5873 10.6344L17.4036 11.6897C17.4951 12.1266 17.5433 12.5793 17.5433 13.0431C17.5433 16.6791 14.5952 19.6272 10.9592 19.6272C7.32329 19.6272 4.3731 16.6791 4.3731 13.0431C4.3731 9.40714 7.32118 6.45905 10.9571 6.45905C11.9807 6.45905 12.9488 6.69275 13.8115 7.10913L14.1692 6.62148L10.9999 4.08605Z" />
                                        <path d="M22.9329 0.345606L14.2297 4.78016L16.9462 6.94135L13.115 11.757L21.8183 7.32245L19.1017 5.16126L22.9329 0.345606Z" />
                                    </symbol>
                                    <use xlinkHref="#icon-target" />
                                </svg>

                            </i>
                            {/**/}
                        </button>

                    </div>
                </div>
                {showCalendar && <Calendar style={{ position: "absolute" }} onChange={e => updateCalendar(e)} value={from} maxDate={new Date()} prevLabel={'<'} nextLabel={'>'} prev2Label={false} next2Label={false} />}
                {showSecondCalendar && <Calendar style={{ position: "absolute" }} onChange={e => updateSecondCalendar(e)} value={to} maxDate={new Date()} prevLabel={'<'} nextLabel={'>'} prev2Label={false} next2Label={false} />}
            </div>
            <app-history _nghost-dmarket-c277="" className="ng-star-inserted">
    <div className="c-history ng-star-inserted">
      <div 
        className="c-historyFeed__note ng-star-inserted"
      >
        <flow-notification _ngcontent-dmarket-c277="">
          <div className="с-exchangeNotification с-exchangeNotification--canBeClosed ng-star-inserted">
            <button className="с-exchangeNotification__close ng-star-inserted">
              <span
                role="img"
                className="mat-icon notranslate material-icons mat-icon-no-color"
                aria-hidden="true"
                data-mat-icon-type="font"
              >
                close
              </span>
            </button>
      
            <div className="с-exchangeNotification__header">
              <p className="с-exchangeNotification__title с-exchangeNotification__title--warn">
                <i
          
                  inlinesvg="icon-info-round.svg"
                  className="o-icon с-exchangeNotification__icon"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.8"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
           Please note

              </p>
            </div>
            
            <p className="с-exchangeNotification__text" style={{display:'flex',flexBasis:'0'}}>
                <div style={{flex:"1 1 0px"}}>Purchases {stats.purchases}</div>
                <div style={{flex:"1 1 0px"}}>Sales {stats.sales}</div>
                <div style={{flex:"1 1 0px"}}>Profit {(stats.sales-stats.purchases).toFixed(2)}</div>
            </p>
          </div>
    
        </flow-notification>
      </div>
  
    </div>
    {/**/}
    {/**/}
    {/**/}
  </app-history>
        </div>
    );
}

export default Finance;