import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import History from "./views/History";
import TargetOrders from "./views/TargetOrders";
import Offers from "./views/Offers";
import Login from "./views/Login";
import Item from "./views/Item";
import axios from "axios";
import { useEffect, useState } from "react";
import Steamfolio from "./views/Steamfolio";
import Finance from "./views/Finance";
import TargetsRenewed from "./views/TargetsRenewed"

function App() {
  let isAuthenticated = async()=>{
    let isAuth = await axios.get("/api/bypass").then(res=> res.data);
    setAuth(isAuth);
  };
  const [auth,setAuth] = useState(false);
  useEffect(() =>{
    isAuthenticated()
  },[])
  // Define the PageNotFound component
  function PageNotFound() {
    return (
      <div>
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  }

  return (
    <>
      <Router>
        {auth && <Navbar />}
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/" Component={auth ? History : Login} />
          <Route exact path="/targets" Component={auth ? TargetOrders : Login} />
          <Route exact path="/favorites" Component={TargetsRenewed} />
          <Route exact path="/login" Component={Login} />
          <Route exact path="/offers" Component={Offers} />
          <Route exact path="/item" Component={Item} />
          <Route exact path="/steamfolio" Component={Steamfolio} />
          <Route exact path="/finance" Component={Finance} />
          <Route path="*" Component={TargetOrders} /> // page-not-found route
          <Route path="*" component={PageNotFound} /> {/* page-not-found route */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
