import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Navbar(props) {
    const navigate = useNavigate()
    const [active,setActive] = useState('History');
    const links = [{name:"History",path:""},{name:"Targets",path:"targets"},{name:"Offers",path:"offers"},{name:"Finance",path:"finance"},{name:"Steamfolio", path:"steamfolio"}];
    useEffect(() =>{
        let current = window.location.pathname;
        if(current == "/"){
            setActive('History');
        }else if(current == "finance"){
            setActive("Finance");
        }else if(current == "steamfolio"){
            setActive("Steamfolio")
        }
        else{
            setActive('Targets')
        }
    },[])
    return (
        <>
        <div className="c-historyDialog__header">
            <div className="c-historyDialog__tabs">
                {links.map(x=> {
                    return (
                        <button className={window.location.pathname == '/'+x.path ? "c-historyDialog__tab is-active" : "c-historyDialog__tab"} 
                                onClick={() => navigate('/'+x.path)}>{x.name}</button>
                    )
                })}
            </div> 
        </div> 
        </>
    );
}

export default Navbar;