import axios from 'axios';
import React, { useEffect, useState } from 'react';

function TargetOrders(props) {
    const [textAreaData,setTextAreaData] = useState([]);
    const [item,setItem] = useState('');
    const dummyData = [{"id":1,"sort_index":3,"name":"Desert Eagle | Printstream (Field-Tested)","active":true,"activeTargets":0,"stock":6},{"id":2,"sort_index":5,"name":"AK-47 | Frontside Misty (Field-Tested)","active":true,"activeTargets":9,"stock":1},{"id":3,"sort_index":5,"name":"M4A4 | Temukau (Field-Tested)","active":true,"activeTargets":0,"stock":5}];
    const filterer = (data) =>{  
        return data;
    }

    const getTargets = async() =>{
      const url = '/api/v2/targets';
      const data = await axios.get(url).then(res=> res.data.data.Items);
      return data
    }
    const doThis = async() =>{
      let copy = await getTargets()
      for (let index = 0; index < copy.length; index++) {
        if(copy[index].check != index){
          copy[index].check = index;
          setTextAreaData(copy);
          setTimeout(() => {
            doThis();
          }, 1000);
          break;
        }
        
      } 
      
    }
    useEffect(() =>{
      setTextAreaData(dummyData)
    },[])

    return (
        <>
       <div className="c-dialog__scroll">
  <div
    style={{
      backgroundColor: "rgb(29, 31, 32)",
      display: "flex",
      padding: "2%",
      overflow: "auto"
    }}
  >
    <div style={{ display: "flex", marginRight: "1%" }}>
      <div className="c-assetFilters__item o-filter o-filter--search">
        <button aria-label="Search" className="o-filter__searchButton">
          <i className="o-filter__icon">
            <svg>
              <symbol id="icon-search" viewBox="0 0 24 24" fill="currentColor">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                />
              </symbol>
              <use xlinkHref="#icon-search" />
            </svg>
          </i>
        </button>
        <label className="o-filter__search ng-untouched ng-pristine ng-valid">
          <input
            type="search"
            aria-label="Search input"
            formcontrolname="title"
            data-hj-whitelist=""
            className="o-filter__searchInput ng-untouched ng-pristine ng-valid" 
            placeholder="Search target"
          />
          <button aria-label="Clear search" className="o-filter__searchClear">
            <i className="o-filter__icon">
              <svg>
                <symbol id="icon-close" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  />
                </symbol>
                <use xlinkHref="#icon-close" />
              </svg>
            </i>
          </button>
        </label>
      </div>
      <div className="c-assetFilters__item ng-star-inserted">
        <button
          aria-label="filter refresh button"
          matripple=""
          className="mat-ripple o-filter o-filter--refresh"
        >
          <i className="o-filter__icon">
            <svg>
              <symbol id="icon-refresh" viewBox="0 0 24 24" fill="currentColor">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
                />
              </symbol>
              <use xlinkHref="#icon-refresh" />
            </svg>
          </i>
        </button>
      </div>
    </div>
   
  </div>
  <app-history _nghost-dmarket-c277="" classname="ng-star-inserted">
    <div className="c-history ng-star-inserted">
      <div className="c-historyFeed__note ng-star-inserted">
        <flow-notification _ngcontent-dmarket-c277="">
          <div className="с-exchangeNotification с-exchangeNotification--canBeClosed ng-star-inserted">
            <button className="с-exchangeNotification__close ng-star-inserted">
              <span
                role="img"
                className="mat-icon notranslate material-icons mat-icon-no-color"
                aria-hidden="true"
                data-mat-icon-type="font"
              >
                close
              </span>
            </button>
            <div className="с-exchangeNotification__header" onClick={() => doThis()}>
              <p className="с-exchangeNotification__title с-exchangeNotification__title--warn">
                <i
                  inlinesvg="icon-info-round.svg"
                  className="o-icon с-exchangeNotification__icon"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.8"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
                Please note
              </p>
            </div>
            <p className="с-exchangeNotification__text">
              The wallet ID has been changed due to the platform’s update
            </p>
          </div>
        </flow-notification>
      </div> 
      <div className="c-history__table ng-star-inserted">
        <div className="c-history__row c-history__row--head">
          <div className="c-history__cell c-history--hide-on-tablets">TargetID</div>
          <div className="c-history__cell c-history--hide-on-tablets">Item</div>
          <div className="c-history__cell c-history--hide-on-tablets">Price</div>
          <div className="c-history__cell c-history--hide-on-tablets">QTY</div>
          <div className="c-history__cell c-history--hide-on-tablets">Edit</div>
        </div>

        {(dummyData.length > 1) && filterer(textAreaData).map(x=> {return (
            <app-history-item className="c-history__row ng-star-inserted">
          
            <div className="c-history__cell c-history__cell--date ng-star-inserted">
              <p>{x.id}</p>
            </div>
            <div className="c-history__cell c-history__cell--date ng-star-inserted">
              <p>{x.name}</p>
            </div>
            <div className="c-history__cell c-history__cell--date ng-star-inserted">
              <p>{x.stock}</p>
            </div>
            <div className="c-history__cell c-history__cell--date ng-star-inserted">
              <p>{x.activeTargets}</p>
            </div>
            <div className="c-history__cell ng-star-inserted">
               {+x.stock > 3 || x.stock < 3 && x.activeTargets == 0? <span
                className="c-history__cellStatus c-history__cellStatus--success" 
              /> : <span style={{backgroundColor:"#ff6464"}}
              className="c-history__cellStatus c-history__cellStatus--failed" 
            />} 
            </div>
               
 
          </app-history-item>)
        })}
        </div>
    </div>
  </app-history>
</div>

        </>
    );
}

export default TargetOrders;