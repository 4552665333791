import React, { useEffect, useState } from 'react';
import axios from "axios";
import Calendar from 'react-calendar';

function History(props) {
  
  const setSortter = type =>{
    let copy = sales.slice();
    if(type == "updated_at"){
    copy.sort((x,y)=> (new Date(x.updated_at)).getTime() - (new Date(y.updated_at)).getTime())
    setSales(copy)
    }else if(type == "profit"){
      copy.sort((x,y)=> (x.sold-x.price) - (y.sold-y.price))
      setSales(copy)
    }else {
      copy.sort((x,y)=> x.price - y.price)
      setSales(copy)
    }
}
  const filteredSales = sales =>{
    return sales.sort((x,y)=> (new Date(x.updated_at)).getTime() - (new Date(y.updated_at)).getTime())
  }
  const total = (sales) =>{
    let tt = 0;
    sales.map(x=> tt = tt+ ((+x.sold)-(x.price)))
    return (tt).toFixed(2);
  }
  const totalUnsold = sales =>{
    let tt = 0;
    let calc = (x) =>{
      tt = tt + x;
    }
    sales.map(x=> !x.sold && calc(+x.price))
    return (tt.toFixed(2));
  }
  const totalProfit = sales =>{
    let tt = 0;
    let calc = (x,y) =>{
      tt = tt + x-y;
    }
    sales.map(x=> x.sold && calc(+x.sold,+x.price))
    return (tt.toFixed(2));
  }
  const totalPurchase = sales =>{
    let tt = 0;
    sales.map(x=> !x.sold && (tt = tt+ x.price) )
    return (tt).toFixed(2);
  }
  const totalSales = sales =>{
    let tt = 0;
    let calc = (x) =>{
      tt = tt + x;
    }
    sales.map(x=> x.sold && calc(+x.sold))
    return (tt).toFixed(2);
  }
  async function paste() {
    const text = await navigator.clipboard.readText();
    document.getElementById("searcher").value = text;
    getSales({"name":text})
  }
  const getSales = async(x) =>{
    if(x.name){
      let url = '/api/v2/sales';
      url = url+'?name='+x.name;
      const data = await axios.get(url).then(res=> res.status == 200 ? res.data : []);
      setSales(data);
    }else if(x.date){
      let url = '/api/v2/sales';
      url = url+'?date='+x.date;
      const data = await axios.get(url).then(res=> res.status == 200 ? res.data : []);
      setSales(data);
    }else{
      let url = '/api/v2/sales';
      url = url+'?limit=50';
      const data = await axios.get(url).then(res=> res.status == 200 ? res.data : []);
      setSales(data);
    }
  }

  const dateFormatter = (date) =>{
    let d = new Date(date);
    let stringFormat = '';
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getUTCFullYear();
    let hour = d.getHours();
    let minutes = d.getMinutes();
    stringFormat = `${day}/${month ? '0'+month:month}/${year} - ${(hour < 10 ? '0'+hour:hour)}:${minutes}`
    return stringFormat;
  }
  const createSale = async() =>{
    let url = '/api/v2/sales';
    let name = document.getElementById("newItem").value;
    let price = +document.getElementById("newItemPrice").value;
    let copy = sales.slice();
    if(price){
      const data = await axios.post(url,{name,price}).then(res=> res.data);
      copy.push(data);
      setSales(copy);
    }
  }

  const updateSold = async(id,value) =>{
    const url = '/api/v2/sales/update';
    if(value == "revert" && sales.find(x=> x.id == id).sold == null){
      const deleteURL = '/api/v2/sales/update';
      axios.post(deleteURL,{id});
      let copy = sales.slice();
      copy = copy.filter(x=> x.id != id);
      setSales(copy)
    }else if(value == "revert"){
      axios.post(url,{id,sold:null});
      let copy = sales.slice();
      copy.find(x=> x.id ==id).sold = null;
      setSales(copy)
    }else{
      let y = document.getElementById(id).value;
      let sold = y;
      axios.post(url,{id,sold});
      let copy = sales.slice();
      copy.find(x=> x.id ==id).sold = +y;
      setSales(copy)
    }
  }
  const initSales = async() =>{
    let x = new Date();
    x.setHours(0);
    x.setMinutes(0);
    x.setSeconds(0);

    await getSales({date:x.getTime()});
  }
  const [sales,setSales] = useState([]);
  const [from,setFrom] = useState(new Date())
  const [sold,setSold] = useState(false)
  const [showCalendar,setShowCalendar] = useState(false);
  useEffect(() => {
    initSales()
  },[])
  const items = [
    {"date":"Jul 27, 2023, 09:56 PM","operation":"Target Closed","item":"AK-47 | Slate (Field-Tested)","price":"1.72"},
    {"date":"Jul 27, 2023, 09:59 PM","operation":"Sell","item":"AK-47 | Slate (Field-Tested)","price":"1.71"},
  ]

  const updateCalendar = e => {
    let date = (new Date(e)).getTime();
    setFrom(e);
    setShowCalendar(false)
    getSales({date})
  }
  const [loading,setLoading] = useState(true);

  const updateLoader = () =>{
    setLoading(false)
    setTimeout(() => {
      setLoading(true)
    }, 1000);
  }
    return (
        <>
        <div className="c-dialog__scroll"> 
     
     {/*
     <div style={{backgroundColor:"#1D1F20",display:"flex",padding:"1%",overflow:"auto"}}>*/}
     <div style={{backgroundColor:"#1D1F20",padding:"2%"}}>
     <div style={{display:"flex",marginRight:"1%",overflow:"auto"}}>
      
      <div className="c-assetFilters__item o-filter o-filter--search">
      <button aria-label="Search" className="o-filter__searchButton">
      <i className="o-filter__icon">
        <svg>
      <symbol id="icon-search" viewBox="0 0 24 24" fill="currentColor">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
        />
      </symbol>
      <use xlinkHref="#icon-search" />
    </svg>
  </i>
  {/**/}
</button>
<label className="o-filter__search ng-untouched ng-pristine ng-valid">
  <input
    id="searcher"
    type="search"
    aria-label="Search input"
    formcontrolname="title"
    data-hj-whitelist=""
    className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
    placeholder="Search market"
    onChange={(e) => getSales({"name":e.target.value})}
  />
  <button aria-label="Clear search" className="o-filter__searchClear" >
    <i className="o-filter__icon">
      <svg>
        <symbol id="icon-close" viewBox="0 0 24 24" fill="currentColor">
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </symbol>
        <use xlinkHref="#icon-close" />
      </svg>
    </i>
    {/**/}
  </button>
</label>
</div>


<div className="c-assetFilters__item ng-star-inserted">
<button
  aria-label="filter refresh button"
  matripple=""
  className="mat-ripple o-filter o-filter--refresh"
  onClick={() => paste()}
>
  <i className="o-filter__icon">
    <svg>
      <symbol id="icon-refresh" viewBox="0 0 24 24" fill="currentColor">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
        />
      </symbol>
      <use xlinkHref="#icon-refresh" />
    </svg>
  </i>
  {/**/}
</button>
</div>

<div style={{display:"flex",width:"100%",justifyContent:"flex-end"}}>
  
<a onClick={e=> sold == "sold" ? setSold(false) : setSold("sold")} class={sold == "sold" ? "o-select__item o-select__item--checkbox ng-star-inserted o-select__item--active" : "o-select__item o-select__item--checkbox ng-star-inserted"}><span class="o-select__item--text">Sold</span></a>
<a onClick={e=> sold == "sale" ? setSold(false) : setSold("sale")} class={sold == "sale" ? "o-select__item o-select__item--checkbox ng-star-inserted o-select__item--active" : "o-select__item o-select__item--checkbox ng-star-inserted"}><span class="o-select__item--text">On Sale</span></a>

</div>
     </div>

     <div style={{display:"flex",marginTop:"2%"}}>
      <div className="c-assetFilters__item o-filter o-filter--search" style={{minWidth:"auto",width:"200px"}}> 
        <input
        id="newItem"
        style={{padding:"10px",opacity:".5"}}
          type="search"
          aria-label="Search input"
          formcontrolname="title"
          data-hj-whitelist=""
          className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
          placeholder="New Item"
        />
</div>

      <div className="c-assetFilters__item o-filter o-filter--search" style={{minWidth:"auto",width:"100px"}}> 
              <input
              id="newItemPrice"
              style={{padding:"10px",opacity:".5"}}
                type="number"
                step={0.01}
                aria-label="Price"
                formcontrolname="title"
                data-hj-whitelist=""
                className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                placeholder="Price"
              />
      </div>

      <div className="c-assetFilters__item o-filter o-filter--search" style={{minWidth:"auto",width:"100px"}}> 
              <input
              style={{padding:"10px",opacity:".5"}}
                type="number"
                step={1}
                aria-label="amount" 
                className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                placeholder="Amount"
                value={1}
              />
      </div>


<button aria-label="Clear search" className="o-filter__searchClear">
          <i className="o-filter__icon">
            <svg>
              <symbol id="icon-close" viewBox="0 0 24 24" fill="currentColor">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                />
              </symbol>
              <use xlinkHref="#icon-close" />
            </svg>
          </i>
          {/**/}
        </button> 


<div className="c-assetFilters__item ng-star-inserted">
<button
  onClick={() => createSale()}
  aria-label="filter refresh button"
  matripple=""
  className="mat-ripple o-filter o-filter--refresh"
>
  <i className="o-filter__icon">
  <svg>
  <symbol id="icon-target" fill="currentColor" viewBox="0 0 25 25">
    <path d="M11.1355 10.7022C11.076 10.698 11.0166 10.6958 10.9571 10.6958C9.65962 10.6958 8.60634 11.7489 8.60629 13.046C8.60629 14.3432 9.65535 15.3963 10.955 15.3963C12.2546 15.3963 13.3057 14.3432 13.3057 13.046C13.3057 12.9866 13.3036 12.9272 13.2993 12.8677L14.9791 11.1884L15.0152 11.1523C15.2849 11.7277 15.4357 12.3688 15.4357 13.0461C15.4357 15.5194 13.431 17.5236 10.9571 17.5236C8.48315 17.5236 6.47852 15.5173 6.47852 13.0439C6.47852 10.5706 8.48315 8.56641 10.9571 8.56641C11.6345 8.56641 12.2758 8.71714 12.8513 8.98677L12.8152 9.02286L11.1355 10.7022Z" />
    <path d="M10.9999 4.08605C10.9849 4.08597 10.97 4.08594 10.9551 4.08594C6.01023 4.08594 2 8.09618 2 13.0431C2 17.99 6.01025 22.0003 10.9572 22.0003C15.9041 22.0003 19.9143 17.99 19.9143 13.0431C19.9143 12.2083 19.8005 11.4006 19.5873 10.6344L17.4036 11.6897C17.4951 12.1266 17.5433 12.5793 17.5433 13.0431C17.5433 16.6791 14.5952 19.6272 10.9592 19.6272C7.32329 19.6272 4.3731 16.6791 4.3731 13.0431C4.3731 9.40714 7.32118 6.45905 10.9571 6.45905C11.9807 6.45905 12.9488 6.69275 13.8115 7.10913L14.1692 6.62148L10.9999 4.08605Z" />
    <path d="M22.9329 0.345606L14.2297 4.78016L16.9462 6.94135L13.115 11.757L21.8183 7.32245L19.1017 5.16126L22.9329 0.345606Z" />
  </symbol>
  <use xlinkHref="#icon-target" />
</svg>

  </i>
  {/**/}
</button>

</div>

     <button onClick={() => setShowCalendar(!showCalendar)}>Date</button>
     </div>
     {showCalendar && <Calendar style={{position:"absolute"}} onChange={e=> updateCalendar(e)} value={from} maxDate={new Date()} prevLabel={'<'} nextLabel={'>'} prev2Label={false} next2Label={false}/>}
     

     </div>


  <app-history _nghost-dmarket-c277="" className="ng-star-inserted">
    <div className="c-history ng-star-inserted">
      <div 
        className="c-historyFeed__note ng-star-inserted"
      >
        <flow-notification _ngcontent-dmarket-c277="">
          <div className="с-exchangeNotification с-exchangeNotification--canBeClosed ng-star-inserted">
            <button className="с-exchangeNotification__close ng-star-inserted">
              <span
                role="img"
                className="mat-icon notranslate material-icons mat-icon-no-color"
                aria-hidden="true"
                data-mat-icon-type="font"
              >
                close
              </span>
            </button>
      
            <div className="с-exchangeNotification__header">
              <p className="с-exchangeNotification__title с-exchangeNotification__title--warn">
                <i
          
                  inlinesvg="icon-info-round.svg"
                  className="o-icon с-exchangeNotification__icon"
                >
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.8"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                      fill="currentColor"
                    />
                  </svg>
                </i>
           Please note

              </p>
            </div>
            
            <p className="с-exchangeNotification__text" style={{display:'flex',flexBasis:'0'}}>
                <div style={{flex:"1 1 0px"}}>Purchases {totalPurchase(sales)}</div>
                <div style={{flex:"1 1 0px"}}>Sales {totalSales(sales)}</div>
                <div style={{flex:"1 1 0px"}}>Profit {totalProfit(sales)}</div>
            </p>
          </div>
    
        </flow-notification>
      </div>

      <div 
        className="c-history__table ng-star-inserted">
        <div   
          className="c-history__row c-history__row--head"
        >
          <div className="c-history__cell" onClick={() => setSortter("updated_at")}>
            Date &amp; Time
          </div>
          <div     
            className="c-history__cell c-history--hide-on-tablets"
          >
            Status
          </div>
          
          <div className="c-history__cell">
            Game
          </div>
          
          <div className="c-history__cell">
            Item/Wallet
          </div>
          <div className="c-history__cell" onClick={() => setSortter()}>
            Buy
          </div> 
          <div className="c-history__cell">
            Sold
          </div> 
          <div className="c-history__cell" onClick={() => setSortter("profit")}>
            Profit
          </div> 
       
          <div className="c-history__cell">
            Status
          </div>
        </div>
        {(filteredSales(sales)?.slice())?.reverse().map(x=> {
          return (
            <app-history-item className="c-history__row ng-star-inserted">
            <div className="c-history__cell c-history__cell--date ng-star-inserted">
              <p>{dateFormatter(x.updated_at)}</p>
            </div>
            <div className="c-history__cell ng-star-inserted">
              {x.sold ? "Sold":"Buy"}
            </div>
            
            <div className="c-history__cell c-history--hide-on-tablets ng-star-inserted">
              <img
                alt="game"
                className="c-history__cellIcon ng-star-inserted"
                src="https://cdn-front.dmarket.com/images-all/5938a31c-0be7-43ee-98ef-8698e47be5b8.jpg"
              />
        
            </div>

            
            <div className="c-history__cell ng-star-inserted">
              <p className="c-history__cellEllipsis c-history__cellEllipsis--lg ng-star-inserted" onClick={() => navigator.clipboard.writeText(x.name)}>
                {x.name}
              </p>
        
            </div>
            <div className="c-history__cell ng-star-inserted">
              <p className="c-history__cellMoney c-history__cellMoney--credit ng-star-inserted">
                <span>{x.price}</span>
                <dm-currency-icon>
                  <span className="o-currencies--USD" />
                </dm-currency-icon>
              </p>
            </div>

            <div className="c-history__cell ng-star-inserted">
              {x.sold ?
              <p className="c-history__cellMoney c-history__cellMoney--debit ng-star-inserted">
                <span>{x.sold}</span>
                <dm-currency-icon>
                  <span className="o-currencies--USD" />
                </dm-currency-icon>
              </p>
              : <div className="c-assetFilters__item o-filter o-filter--search">
              <label className="o-filter__search ng-untouched ng-pristine ng-valid" >
                <input
                  id={x.id}
                  style={{border:"1px solid #323436"}}
                  type="search"
                  aria-label="Search input"
                  formcontrolname="title"
                  data-hj-whitelist=""
                  className="o-filter__searchInput ng-untouched ng-pristine ng-valid ng-update"
                  placeholder="Price"
                /> 
              </label>
            </div>
              }
            </div>

            <div className="c-history__cell ng-star-inserted">
              {x.sold ?
              <p className={`c-history__cellMoney c-history__cellMoney--${x.sold > x.price ? "debit":"credit"} ng-star-inserted`}>
                <span>{(x.sold-x.price).toFixed(2) > 0 ? (x.sold-x.price).toFixed(2) : (-1*(x.sold-x.price)).toFixed(2)}</span>
                <dm-currency-icon>
                  <span className="o-currencies--USD" />
                </dm-currency-icon>
              </p>
              : <div>
                <button _ngcontent-dmarket-c192="" mat-flat-button="" color="green" onClick={()=> updateSold(x.id)}
                class="mat-focus-indicator c-pagination__load mat-flat-button mat-button-base mat-green ng-star-inserted approve"><span class="mat-button-wrapper">✓ </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button></div>
              }
            </div>
            
            <div className="c-history__cell ng-star-inserted" onClick={() =>updateSold(x.id,"revert")}>
              <span
                className="c-history__cellStatus c-history__cellStatus--success"
                title="success"
              />
            </div>
          </app-history-item>
        
          )
        })}
         
         <br></br>
        <p style={{padding:"10px"}}> {total(sales)}</p>
        <p style={{padding:"10px"}}> From : {totalPurchase(sales)}</p>
        <p style={{padding:"10px"}}> Sold : {totalSales(sales)}</p>
        <p style={{padding:"10px"}}> Profit : {totalProfit(sales)}</p>

        
        <p style={{padding:"10px"}}> Unsold : {totalUnsold(sales)}</p>
  
      </div>


 
    </div>
    {/**/}
    {/**/}
    {/**/}
  </app-history>

</div>


<app-pagination-dm
  _ngcontent-dmarket-c281=""
  role="navigation"
  scrolltotop="true"
  className="c-history__pagination"
  _nghost-dmarket-c192=""
>
  <div     _ngcontent-dmarket-c192=""
    className="c-pagination ng-star-inserted"
    
  >
    <button
      _ngcontent-dmarket-c192=""
      mat-flat-button=""
      color="green"
      className="mat-focus-indicator c-pagination__load mat-flat-button mat-button-base mat-green ng-star-inserted"
      onClick={() => updateLoader()}
    >
      <span className="mat-button-wrapper" >{loading ? 'Load More' : <span class="loader" style={{width:"25px",height:"25px"}}></span>} </span>
      <span matripple="" className="mat-ripple mat-button-ripple" />
      <span className="mat-button-focus-overlay" />
    </button>
    {/**/} 
  </div>
</app-pagination-dm>

<ul _ngcontent-dmarket-c192="" className="c-pagination__list ng-star-inserted">
  {/**/}
  <li
    _ngcontent-dmarket-c192=""
    className="c-pagination__item ng-star-inserted"
  >
    {/**/}
    <div
      _ngcontent-dmarket-c192=""
      className="c-pagination__link c-pagination__arrow ng-star-inserted"
    >
      <span
        _ngcontent-dmarket-c192=""
        role="img"
        className="mat-icon notranslate material-icons mat-icon-no-color"
        aria-hidden="true"
        data-mat-icon-type="font"
      >
        keyboard_arrow_left
      </span>
    </div>
    {/**/}
  </li>
  {/**/}
  <li 
    className="c-pagination__item c-pagination__item--active ng-star-inserted"
  >
     
    <div
      _ngcontent-dmarket-c192=""
      className="c-pagination__link ng-star-inserted"
    >
      {" "}
      1{" "}
    </div>
    {/**/}
  </li>
  <li
    _ngcontent-dmarket-c192=""
    className="c-pagination__item ng-star-inserted c-pagination__item--active"
  >
    {/**/}
    {/**/}
    <div
      _ngcontent-dmarket-c192=""
      className="c-pagination__link ng-star-inserted"
    >
      {" "}
      2{" "}
    </div>
    {/**/}
  </li>
  <li
    _ngcontent-dmarket-c192=""
    className="c-pagination__item ng-star-inserted"
  >
    {/**/}
    {/**/}
    <div
      _ngcontent-dmarket-c192=""
      className="c-pagination__link ng-star-inserted"
    >
      {" "}
      3{" "}
    </div>
    {/**/}
  </li>
  <li
    _ngcontent-dmarket-c192=""
    className="c-pagination__item disabled ng-star-inserted"
  >
    <a
      _ngcontent-dmarket-c192=""
      aria-label="pagination link"
      className="c-pagination__link ng-star-inserted"
    >
      ...
    </a>
    {/**/}
    {/**/}
    {/**/}
  </li>
  <li
    _ngcontent-dmarket-c192=""
    className="c-pagination__item ng-star-inserted"
  >
    {/**/}
    {/**/}
    <div
      _ngcontent-dmarket-c192=""
      className="c-pagination__link ng-star-inserted"
    >
      {" "}
      67{" "}
    </div>
    {/**/}
  </li>
  {/**/}
  {/**/}
  {/**/}
  <li
    _ngcontent-dmarket-c192=""
    className="c-pagination__item ng-star-inserted"
  >
    {/**/}
    <div
      _ngcontent-dmarket-c192=""
      className="c-pagination__link c-pagination__arrow ng-star-inserted"
    >
      <span
        _ngcontent-dmarket-c192=""
        role="img"
        className="mat-icon notranslate material-icons mat-icon-no-color"
        aria-hidden="true"
        data-mat-icon-type="font"
      >
        keyboard_arrow_right
      </span>
    </div>
    {/**/}
  </li>
  {/**/}
  {/**/}
</ul>

</>
    );
}

export default History;