import axios from "axios";
import React, { useEffect, useState } from 'react';

function Steamfolio(props) {
  const url = '/api/v2/steamfolio';
  const [popular, setPopular] = useState([]);
  useEffect(() => {
    axios.get(url).then(res => {
      if (res.data.success == true) {
        setPopular(res.data.data.items)
      }
    }).catch(err => alert("API error"));

  }, [])
  return (
    <div className="c-dialog__scroll">
      <div
        style={{
          backgroundColor: "rgb(29, 31, 32)",
          display: "flex",
          padding: "2%",
          overflow: "auto"
        }}
      >
        <div style={{ display: "flex", marginRight: "1%" }}>
          <div className="c-assetFilters__item o-filter o-filter--search">
            <button aria-label="Search" className="o-filter__searchButton">
              <i className="o-filter__icon">
                <svg>
                  <symbol id="icon-search" viewBox="0 0 24 24" fill="currentColor">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                    />
                  </symbol>
                  <use xlinkHref="#icon-search" />
                </svg>
              </i>
            </button>
            <label className="o-filter__search ng-untouched ng-pristine ng-valid">
              <input
                type="search"
                aria-label="Search input"
                formcontrolname="title"
                data-hj-whitelist=""
                className="o-filter__searchInput ng-untouched ng-pristine ng-valid"
                placeholder="Search steamfolio"
              />
              <button aria-label="Clear search" className="o-filter__searchClear">
                <i className="o-filter__icon">
                  <svg>
                    <symbol id="icon-close" viewBox="0 0 24 24" fill="currentColor">
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                      />
                    </symbol>
                    <use xlinkHref="#icon-close" />
                  </svg>
                </i>
              </button>
            </label>
          </div>
          <div className="c-assetFilters__item ng-star-inserted">
            <button
              aria-label="filter refresh button"
              matripple=""
              className="mat-ripple o-filter o-filter--refresh"
            >
              <i className="o-filter__icon">
                <svg>
                  <symbol id="icon-refresh" viewBox="0 0 24 24" fill="currentColor">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"
                    />
                  </symbol>
                  <use xlinkHref="#icon-refresh" />
                </svg>
              </i>
            </button>
          </div>
        </div>

      </div>
      <app-history _nghost-dmarket-c277="" className="ng-star-inserted">
        <div className="c-history ng-star-inserted">

          <div
            className="c-history__table ng-star-inserted">
            <div
              className="c-history__row c-history__row--head"
            >
              <div className="c-history__cell" >
                ID
              </div>
              <div
                className="c-history__cell c-history--hide-on-tablets"
              >
                Trending
              </div>

              <div className="c-history__cell">
                Image
              </div>

              <div className="c-history__cell">
                Item
              </div>
              <div className="c-history__cell">
                Daily
              </div>
              <div className="c-history__cell">
                Weekly
              </div>
              <div className="c-history__cell">
                Monthly
              </div>

              <div className="c-history__cell">
                Status
              </div>
            </div>

            {popular.map((skin, index) => {
              return (
                <app-history-item className="c-history__row ng-star-inserted">
                  <div className="c-history__cell c-history__cell--date ng-star-inserted">
                    <p>{index + 1}#</p>
                  </div>
                  <div className="c-history__cell ng-star-inserted">
                    {skin.changeWeekPercentage < -5 && skin.changeMonthPercentage < -5  ? "Downtrend" : "Uptrend"}
                  </div>

                  <div className="c-history__cell c-history--hide-on-tablets ng-star-inserted">
                    <img
                      alt="game"
                      className="ng-star-inserted"
                      src={skin.image}
                      style={{ maxWidth: "50px" }}
                    />
                  </div>


                  <div className="c-history__cell ng-star-inserted">
                    <p className="c-history__cellEllipsis c-history__cellEllipsis--lg ng-star-inserted" onClick={() => navigator.clipboard.writeText(skin.name)}>
                      {skin.name}
                    </p>

                  </div>
                  <div className="c-history__cell ng-star-inserted">
                    {((skin.marketPrice) / 100).toFixed(2)}$
                    <p className={`c-history__cellMoney c-history__cellMoney--${skin.changeDayPercentage > 0 ? "debit" : "credit"} ng-star-inserted`}>
                      <span>
                        {(skin.changeDayPercentage > 0 ? skin.changeDayPercentage : skin.changeDayPercentage * -1).toFixed(2)}%</span>
                    </p>
                  </div>
                  <div className="c-history__cell ng-star-inserted">
                    {(skin.averagePriceWeek).toFixed(2)}$
                    <p className={`c-history__cellMoney c-history__cellMoney--${skin.changeWeekPercentage > 0 ? "debit" : "credit"} ng-star-inserted`}>
                      <span>
                        {(skin.changeWeekPercentage > 0 ? skin.changeWeekPercentage : skin.changeWeekPercentage * -1).toFixed(2)}%</span>
                    </p>
                  </div>
                  <div className="c-history__cell ng-star-inserted">
                    {(skin.averagePriceMonth).toFixed(2)}$
                    <p className={`c-history__cellMoney c-history__cellMoney--${skin.changeMonthPercentage > 0 ? "debit" : "credit"} ng-star-inserted`}>
                      <span>
                        {(skin.changeMonthPercentage > 0 ? skin.changeMonthPercentage : skin.changeMonthPercentage * -1).toFixed(2)}%</span>
                    </p>
                  </div>

                  <div className="c-history__cell ng-star-inserted" >
                    <span
                      className="c-history__cellStatus c-history__cellStatus--success"
                      title="success"
                    />
                  </div>
                </app-history-item>)
            })}
          </div>
        </div>
      </app-history>
    </div>
  );
}

export default Steamfolio;